import React, { useEffect } from "react";
import { UilPadlock } from "@iconscout/react-unicons";
import { useState } from "react";
import Header from "../../../Components/Header/Header";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
// importing image assets
import Strategy from "./Assets/Strategy.png";
import Execution from "./Assets/Execution.png";
import Project1Main from "./Assets/Project1Main.jpg";

import Aos from "aos";
import "aos/dist/aos.css";

// import "./style.css";

function Project3() {
  useEffect(() => {
    Aos.init({
      duration: 600,
      easing: "ease-in-out",
    });
  }, []);
  const [password, setPassword] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);

  const correctPassword = "welcome";

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = () => {
    if (password === correctPassword) {
      setLoggedIn(true);
    } else {
      alert("Incorrect password. Please try again.");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin(); // Call the login function when Enter is pressed
    }
  };

  return (
    <div>
      {!loggedIn ? (
        <div id="passwordContainer">
          <UilPadlock size="100" />
          <div id="passText">Enter password to view project</div>
          <input
            id="passInput"
            type="password"
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
            onKeyDown={handleKeyDown}
          />
          <button id="passButton" onClick={handleLogin}>
            Login
          </button>
        </div>
      ) : (
        <div className="project1">
          <Header></Header>
          <div className="project1__container">
            <div className="projectInfoContainer">
              <div className="projectInfoText">
                <div className="projectInfoTitle">Pushpay</div>
                <div className="projectInfoDesc">
                  Helping customers engage and build their communities through
                  innovative app and giving solutions.
                </div>
              </div>
              <div className="projectInfoImgContainer">
                <img className="projectInfoImg" src={Project1Main} alt="" />
              </div>
            </div>
            <div className="projectContent">
              <div className="leftCol">
                <div className="projectMenu">
                  <div className="border"></div>
                  <a href="#Discovery" className="projectMenuItem">
                    Discovery
                  </a>
                  <a href="#Execution" className="projectMenuItem">
                    Execution
                  </a>
                  <a href="#Conclusion" className="projectMenuItem">
                    Conclusion
                  </a>
                  <div className="border"></div>
                </div>
                
              </div>
              <div className="rightCol">
                <div id="projectDescAdd" className="projectDescription">
                  During my previous endeavor at Pushpay, I worked alongside the
                  App Production Team to build world-class giving and engagement
                  solutions to help organizations grow their communities. The
                  goal is to create an app that allows non-profits and church
                  congregations to stay connected and informed virtually. To
                  showcase my time at Pushpay I will be highlighting my work
                  with Colonial Heights Church.
                </div>
                <div className="border"></div>
                <div id="Discovery" className="projectTitle">
                  Discovery
                </div>
                <div className="projectDescription">
                  My research involved a systematic research process to
                  understand the needs of the church community, identify
                  potential features, and ensure that the app aligns with the
                  church's mission and values. Interviews with key stakeholders
                  within the church, including pastors, staff members,
                  volunteers, and members of the congregation helped me gain
                  insight into their needs, pain points, and what they would
                  like to see in the app. In addition to these things I also
                  received access to their existing brand guidelines that I
                  would utilize for this app creation.
                  <br /> <br /> <br />
                  My findings from these interviews were that the church hopes
                  to improve communication, enhance engagement, promote events,
                  manage donations, and offer spiritual resources.
                </div>
                <Zoom>
                <img className="projectShowcaseImg" src={Strategy} alt=""></img>
                </Zoom>
                <div className="imageCaption">
                  Research consisted of thorough user interviews and an analysis
                  of the client’s existing brand.
                </div>
                <div id="Execution" className="projectTitle">
                  Execution
                </div>
                <div className="projectDescription">
                  Low-fidelity mockups are valuable in the early stages of the
                  design process for exploration, concept validation, and
                  efficient communication. Using my earlier wireframes as a
                  baseline, I build on previoues concepts and create a more
                  detailed picture of what the app will look like. Once these
                  are complete, high-fidelity mockups are created to fine-tune
                  the user experience, achieve visual consistency, gain
                  stakeholder approval, and guide the development process. Both
                  types of mockups complement each other, contributing to a
                  successful and well-executed design project.
                </div>
                <Zoom>

                <img
                  className="projectShowcaseImg"
                  src={Execution}
                  alt=""
                  ></img>
                  </Zoom>
                <div className="imageCaption">
                  Mockups confirm the effectiveness of user flows before moving
                  on to high fidelity prototyping.
                </div>
                <div id="Conclusion" className="projectTitle">
                  Conclusion
                </div>
                <div className="projectDescription">
                  After examining user feedback and mock-ups, I updated the
                  designs and created a final app walkthrough.
                </div>
                <iframe
                  className="iframe"
                  title="High Fidelty Prototype"
                  id="project1FinalFrame"
                  width="100%"
                  height="840px"
                  src="https://www.youtube.com/embed/WDmhm3iIapM"
                  allowfullscreen
                ></iframe>
                <div className="imageCaption">
                  Watch the app walkthrough for Colonial Heights above.
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Project3;
