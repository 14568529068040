import React, { useEffect } from "react";
import Header from "../../../Components/Header/Header";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
// importing image assets
import Icon1 from "./Assets/Icon1.png";
import Icon2 from "./Assets/Icon2.png";
import Strategy from "./Assets/Strategy.png";
import Execution from "./Assets/Execution.png";
import Conclusion from "./Assets/Conclusion.png";
import Project1Main from "./Assets/Project1Main.png";

import Aos from "aos";
import "aos/dist/aos.css";

import "./style.css";

function Project5() {
  useEffect(() => {
    Aos.init({
      duration: 600,
      easing: "ease-in-out",
    });
  }, []);

  return (
    <div>
        <div className="project1">
          <Header></Header>
          <div className="project1__container">
            <div className="projectInfoContainer">
              <div className="projectInfoText">
                <div className="projectInfoTitle">The Nest</div>
                <div className="projectInfoDesc">
                  Effortless onboarding, seamless management for thriving teams.
                </div>
              </div>
              <div className="projectInfoImgContainer">
                <img className="projectInfoImg" src={Project1Main} alt="" />
              </div>
            </div>
            <div className="projectContent">
              <div className="leftCol">
                <div className="projectMenu">
                  <div className="border"></div>
                  <a href="#Discovery" className="projectMenuItem">
                    Discovery
                  </a>
                  <a href="#Strategy" className="projectMenuItem">
                    Strategy
                  </a>
                  <a href="#Execution" className="projectMenuItem">
                    Execution
                  </a>
                  <a href="#Conclusion" className="projectMenuItem">
                    Conclusion
                  </a>
                  <div className="border"></div>
                </div>
              </div>
              <div className="rightCol">
                <div id="projectDescAdd" className="projectDescription">
                  The summer sales season is pivotal for businesses dependent on
                  D2D sales. Juggling the recruitment of contract workers,
                  managing housing, and fostering a competitive yet healthy
                  environment can pose challenges. For Hawx Pest Control, much
                  of their success hinges on these summer months. To navigate
                  this demanding period effectively, Hawx needed an employee
                  management system tailored to their specific requirements.
                </div>
                <div className="border"></div>
                <div id="Discovery" className="projectTitle">
                  Discovery
                </div>
                <div className="projectDescription">
                  {" "}
                  I was provided with a SOW (scope of work) by the director of
                  project management and the CRO. While analyzing the document I
                  was able to pinpoint some key issues that their current system
                  was facing:
                </div>
                <div className="projectShowcaseA">
                  <div className="projectShowcaseCard">
                    <img
                      className="projectShowcaseCardImg"
                      src={Icon1}
                      alt=""
                    ></img>
                    <div className="projectShowcaseCardTitle">
                      Visual Design
                    </div>
                    <div className="projectShowcaseCardDesc">
                      The visual design for their current system was quite
                      inconsistent. My solution was to utilize a design system
                      to create uniformity throughout the project.
                    </div>
                  </div>
                  <div className="projectShowcaseCard">
                    <img
                      className="projectShowcaseCardImg"
                      src={Icon2}
                      alt=""
                    ></img>
                    <div className="projectShowcaseCardTitle">
                      {" "}
                      Poor User Flows
                    </div>
                    <div className="projectShowcaseCardDesc">
                      The present website frequently led users to become
                      disoriented or unsure of where to locate information. To
                      address this, I decided to utilize patterns that users are
                      already accustomed to across the system.
                    </div>
                  </div>
                </div>
                <div id="Strategy" className="projectTitle">
                  Strategy
                </div>
                <div className="projectDescription">
                  Utilizing an existing design system offers the advantage of
                  saving time and ensuring consistency across various aspects of
                  a project. By leveraging established patterns, components, and
                  guidelines, I can avoid reinventing the wheel, thus
                  streamlining the design and development process. When on a
                  timeline of 90 days from research to design deliverables, this
                  not only sped up the workflow but also enhanced the user
                  experience by maintaining coherence and familiarity throughout
                  the interface. Adopting an existing design system promoted
                  efficiency, cohesion, and a polished end product.
                </div>
                <Zoom><img className="projectShowcaseImg" src={Strategy} alt=""></img></Zoom>
                <div className="imageCaption">
                  Untitled UI is a fantastic design system that I was able to
                  leverage for this project.
                </div>

                <div id="Execution" className="projectTitle">
                  Execution
                </div>
                <div className="projectDescription">
                  Due to the fact that this project is a redesign, the
                  stakeholders knew what content they wanted on the website.
                  This streamlined the wireframing and low fidelity mockup phase
                  and allowed me to move quickly into high fidelity mockups.
                </div>
                <Zoom>

                <img
                  className="projectShowcaseImg"
                  src={Execution}
                  alt=""
                  ></img>
                  </Zoom>
                <div className="imageCaption">
                  Above are some early before and after designs for The Nest.
                </div>
                <div id="Conclusion" className="projectTitle">
                  Conclusion
                </div>
                <div className="projectDescription">
                  This project exemplified the effective use of available
                  resources to produce the optimal product. As a designer, it's
                  often tempting to meticulously complete every step of the
                  design process, from research to building a design system from
                  the ground up. However, each project is unique, and while that
                  may align with my personal preferences, it may not always
                  serve the stakeholders' best interests. The objectives were
                  straightforward: refine user flows, enhance design
                  consistency, and organize data in a user-friendly manner. By
                  leveraging the Utilited UI design system, I successfully
                  developed a more intuitive website for sales onboarding and
                  employee management.
                </div>
                <Zoom>
                <img
                  className="projectShowcaseImg"
                  src={Conclusion}
                  alt=""
                  ></img>
                </Zoom>
                <div className="imageCaption">
                A few of the final deliverable screens for The Nest.
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

export default Project5;
