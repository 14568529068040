import React from "react";
import "./style.css";

function Header() {

    return(
        <div id="headerContainer">
            <a  id="headerText" href="/">
            <div>sydney martin</div>
            </a>
        </div>
    )
}

export default Header;