import React, { useEffect } from "react";
import Header from "../../../Components/Header/Header";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
// importing image assets
import Strategy from "./Assets/Strategy.png";
import Execution from "./Assets/Execution.png";
import Execution2 from "./Assets/Execution2.png";
import Project1Main from "./Assets/Project1Main.png";
import Aos from "aos";
import "aos/dist/aos.css";

import "./style.css";

function Project6() {
  useEffect(() => {
    Aos.init({
      duration: 600,
      easing: "ease-in-out",
    });
  }, []);
  return (
    <div>
        <div className="project1">
          <Header></Header>
          <div className="project1__container">
            <div className="projectInfoContainer">
              <div className="projectInfoText">
                <div className="projectInfoTitle">Wick Design System</div>
                <div className="projectInfoDesc">
                  Building the ultimate online community for investors to learn,
                  engage, and connect.
                </div>
              </div>
              <div className="projectInfoImgContainer">
                <img className="projectInfoImg" src={Project1Main} alt="" />
              </div>
            </div>
            <div className="projectContent">
              <div className="leftCol">
                <div className="projectMenu">
                  <div className="border"></div>
                  <a href="#Discovery" className="projectMenuItem">
                    Discovery
                  </a>
                  <a href="#Strategy" className="projectMenuItem">
                    Strategy
                  </a>
                  <a href="#Execution" className="projectMenuItem">
                    Execution
                  </a>
                  <a href="#Conclusion" className="projectMenuItem">
                    Conclusion
                  </a>
                  <div className="border"></div>
                </div>
              </div>
              <div className="rightCol">
                <div id="projectDescAdd" className="projectDescription">
                  Wick is the ultimate online mobile community designed for
                  investors of every type. Our primary goal is to establish a
                  welcoming hub where users can connect with fellow investors,
                  engage with experts, and use our revolutionary tools to
                  conduct due diligence with confidence. We firmly believe that
                  investing should be an enjoyable and straightforward endeavor,
                  devoid of intimidation or confusion.
                </div>
                <div className="border"></div>
                <div id="Discovery" className="projectTitle">
                  Discovery
                </div>
                <div className="projectDescription">
                  {" "}
                  As the sole product designer at Wick, I understood the crucial
                  role of advocating for design early on and the necessity of
                  establishing a durable foundation to ensure long-term success.
                  Naturally, this led me to develop a design system for Wick.
                  Being at the company from it’s infancy gave me the unique
                  opportunity to have an big impact and building a design system
                  from the ground up was what I was most excited for. With
                  cutting edge features such as variables and components, I knew
                  that Figma would be the right software for this task.
                </div>
                <div className="projectQuoteBreak">
                  “We firmly believe that investing should be an enjoyable and
                  straightforward endeavor, devoid of intimidation or
                  confusion.Our platform is a testament to this belief, with a
                  user-friendly interface and advanced features that transform
                  investing into a pleasurable experience.”
                </div>

                <div id="Strategy" className="projectTitle">
                  Strategy
                </div>
                <div className="projectDescription">
                  In a bit of a “chicken or the egg” situation, the design
                  system could not take shape until early mockups were created.
                  I needed the greater context of what were building before I
                  could pinpoint what were the MVP features within our design
                  system. After that, a competitive audit of design systems that
                  I admired was the natural next step. This included looking at
                  Google’s Material Design, Microsoft’s Fluent, Atlassian’s
                  Design System, and IBM’s Carbon.
                </div>
                <Zoom>
                  <img
                    className="projectShowcaseImg"
                    src={Strategy}
                    alt=""
                  ></img>
                </Zoom>
                <div className="imageCaption">
                  There is so much to be learned from great design system’s from
                  industry leaders.
                </div>

                <div id="Execution" className="projectTitle">
                  Execution
                </div>
                <div className="projectDescription">
                  Creating a design system that would meet our needs as a
                  rapidly growing startup was of the upmost importance to the
                  team. I frequently gathered feedback from the executive team
                  to ensure I was on the right track and the system I was
                  creating, aligned with the company’s vision. Below are
                  screenshots of the Figma page naming and an intro statement
                  about the Wick Design System.
                </div>
                <Zoom>
                  <img
                    className="projectShowcaseImg"
                    src={Execution}
                    alt=""
                  ></img>
                </Zoom>
                <div className="imageCaption">
                  Keeping organized and orienting the designer is key for
                  setting up a design system. Giving a point of contact,
                  highlighting key features, and reminding them of the company
                  goals are all important.
                </div>
                <div className="projectDescription">
                  I created components, variables, and tokens in hopes of
                  speeding up the design process in the future, and
                  communicating better with the development team. I have
                  included screenshots below of some of the design system pages
                  in Figma.
                </div>
                <Zoom>
                  <img
                    className="projectShowcaseImg"
                    src={Execution2}
                    alt=""
                  ></img>
                </Zoom>
                <div className="imageCaption">
                  A condensed version of the ever-evolving Wick Design System.
                </div>
                <div id="Conclusion" className="projectTitle">
                  Conclusion
                </div>
                <div className="projectDescription">
                  While I am happy with where our design system is at today, I
                  am even more excited to see how it will evolve in the coming
                  months. As more designers are brought on and we grow, we fully
                  intend for the design system to grow with us. Every new
                  feature request and sprint, is an opportunity to utilize our
                  design system to the best of it’s ability, and alter it to
                  meet our ever changing needs.
                </div>
                <div className="bottomSpacer"></div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

export default Project6;
