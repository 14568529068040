import React, { useEffect } from "react";
import Header from "../../../Components/Header/Header";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
// importing image assets
import Icon1 from "./Assets/Icon1.png";
import Icon2 from "./Assets/Icon2.png";
import Icon3 from "./Assets/Icon3.png";
import Icon4 from "./Assets/Icon4.png";
import Strategy from "./Assets/Strategy.png";
import Execution from "./Assets/Execution.png";
import Execution2 from "./Assets/Execution2.png";
import Project1Main from "./Assets/Project1Main.png";

import Aos from "aos";
import "aos/dist/aos.css";

// import "./style.css";

function Project4() {
  useEffect(() => {
    Aos.init({
      duration: 600,
      easing: "ease-in-out",
    });
  }, []);
  return (
    <div>
        <div className="project1">
          <Header></Header>
          <div className="project1__container">
            <div className="projectInfoContainer">
              <div className="projectInfoText">
                <div className="projectInfoTitle">Hawx</div>
                <div className="projectInfoDesc">
                  Changing the pest control industry one exceptional service at
                  a time.
                </div>
              </div>
              <div className="projectInfoImgContainer">
              <img className="projectInfoImg" src={Project1Main} alt="" />
                </div>
            </div>
            <div className="projectContent">
              <div className="leftCol">
                <div className="projectMenu">
                  <div className="border"></div>
                  <a href="#Discovery" className="projectMenuItem">
                    Discovery
                  </a>
                  <a href="#Execution" className="projectMenuItem">
                    Execution
                  </a>
                  <a href="#Conclusion" className="projectMenuItem">
                    Conclusion
                  </a>
                  <div className="border"></div>
                </div>
                {/* <div className="miniFooter">
                  <div className="miniFooterIcon" alt="">
                    <svg
                      id="miniFooterIconSvg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M10.5883 15.3024L9.40982 16.4809C7.78264 18.1081 5.14445 18.1081 3.51726 16.4809C1.89008 14.8537 1.89008 12.2155 3.51726 10.5883L4.69577 9.40982M15.3024 10.5883L16.4809 9.40982C18.1081 7.78264 18.1081 5.14445 16.4809 3.51726C14.8537 1.89008 12.2155 1.89008 10.5883 3.51726L9.40982 4.69577M7.08241 12.9157L12.9157 7.08239"
                        stroke="#344054"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="miniFooterIcon" alt="">
                    <svg
                      id="miniFooterIconSvg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M6.2896 18.126C13.8368 18.126 17.9648 11.8732 17.9648 6.45084C17.9648 6.27324 17.9648 6.09644 17.9528 5.92044C18.7559 5.33957 19.4491 4.62034 20 3.79644C19.2512 4.12844 18.4567 4.34607 17.6432 4.44204C18.4998 3.92928 19.141 3.12269 19.4472 2.17244C18.6417 2.65045 17.7605 2.9873 16.8416 3.16844C16.2229 2.51059 15.4047 2.07497 14.5135 1.92901C13.6223 1.78305 12.7078 1.93487 11.9116 2.36098C11.1154 2.7871 10.4819 3.46375 10.109 4.28623C9.73605 5.10871 9.64462 6.03116 9.8488 6.91084C8.21741 6.82901 6.62146 6.40503 5.16455 5.66644C3.70763 4.92786 2.4223 3.89116 1.392 2.62364C0.867274 3.52697 0.70656 4.59633 0.942583 5.61399C1.17861 6.63165 1.79362 7.5211 2.6624 8.10124C2.00936 8.08211 1.37054 7.90594 0.8 7.58764V7.63964C0.800259 8.58702 1.12821 9.50514 1.72823 10.2383C2.32824 10.9714 3.16338 11.4744 4.092 11.662C3.4879 11.8268 2.85406 11.8509 2.2392 11.7324C2.50151 12.5477 3.01202 13.2606 3.69937 13.7716C4.38671 14.2825 5.21652 14.5658 6.0728 14.582C5.22203 15.2508 4.24776 15.7452 3.20573 16.037C2.16369 16.3289 1.07435 16.4124 0 16.2828C1.87653 17.487 4.05994 18.1258 6.2896 18.1228"
                        fill="#98A2B3"
                      />
                    </svg>
                  </div>
                  <div className="miniFooterIcon" alt="">
                    <svg
                      id="miniFooterIconSvg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_1211_2232)">
                        <path
                          d="M20 10C20 4.47715 15.5229 0 10 0C4.47715 0 0 4.47715 0 10C0 14.9912 3.65684 19.1283 8.4375 19.8785V12.8906H5.89844V10H8.4375V7.79688C8.4375 5.29063 9.93047 3.90625 12.2146 3.90625C13.3084 3.90625 14.4531 4.10156 14.4531 4.10156V6.5625H13.1922C11.95 6.5625 11.5625 7.3334 11.5625 8.125V10H14.3359L13.8926 12.8906H11.5625V19.8785C16.3432 19.1283 20 14.9912 20 10Z"
                          fill="#98A2B3"
                        />
                        <path
                          d="M13.8926 12.8906L14.3359 10H11.5625V8.125C11.5625 7.33418 11.95 6.5625 13.1922 6.5625H14.4531V4.10156C14.4531 4.10156 13.3088 3.90625 12.2146 3.90625C9.93047 3.90625 8.4375 5.29063 8.4375 7.79688V10H5.89844V12.8906H8.4375V19.8785C9.47287 20.0405 10.5271 20.0405 11.5625 19.8785V12.8906H13.8926Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1211_2232">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="miniFooterIcon" alt="">
                    <svg
                      id="miniFooterIconSvg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_1211_1928)">
                        <path
                          d="M18.5236 0H1.47639C1.08483 0 0.709301 0.155548 0.432425 0.432425C0.155548 0.709301 0 1.08483 0 1.47639V18.5236C0 18.9152 0.155548 19.2907 0.432425 19.5676C0.709301 19.8445 1.08483 20 1.47639 20H18.5236C18.9152 20 19.2907 19.8445 19.5676 19.5676C19.8445 19.2907 20 18.9152 20 18.5236V1.47639C20 1.08483 19.8445 0.709301 19.5676 0.432425C19.2907 0.155548 18.9152 0 18.5236 0ZM5.96111 17.0375H2.95417V7.48611H5.96111V17.0375ZM4.45556 6.1625C4.11447 6.16058 3.7816 6.05766 3.49895 5.86674C3.21629 5.67582 2.99653 5.40544 2.8674 5.08974C2.73826 4.77404 2.70554 4.42716 2.77336 4.09288C2.84118 3.7586 3.0065 3.4519 3.24846 3.21148C3.49042 2.97107 3.79818 2.80772 4.13289 2.74205C4.4676 2.67638 4.81426 2.71133 5.12913 2.84249C5.44399 2.97365 5.71295 3.19514 5.90205 3.47901C6.09116 3.76288 6.19194 4.09641 6.19167 4.4375C6.19488 4.66586 6.15209 4.89253 6.06584 5.104C5.97959 5.31547 5.85165 5.50742 5.68964 5.66839C5.52763 5.82936 5.33487 5.95607 5.12285 6.04096C4.91083 6.12585 4.68389 6.16718 4.45556 6.1625ZM17.0444 17.0458H14.0389V11.8278C14.0389 10.2889 13.3847 9.81389 12.5403 9.81389C11.6486 9.81389 10.7736 10.4861 10.7736 11.8667V17.0458H7.76667V7.49306H10.6583V8.81667H10.6972C10.9875 8.22917 12.0042 7.225 13.5556 7.225C15.2333 7.225 17.0458 8.22083 17.0458 11.1375L17.0444 17.0458Z"
                          fill="#98A2B3"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1211_1928">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div> */}
              </div>
              <div className="rightCol">
                <div id="projectDescAdd" className="projectDescription">
                  During my tenure at Hawx, it became evident that as we
                  integrated technology into our operations, our outdated
                  website was detrimental to our brand image. We made it a top
                  priority to create a user-friendly, tech-forward, and
                  responsive website. By placing a strong emphasis on innovation
                  and technology, I played a key role in distinguishing our
                  business in a competitive market, while also meeting the needs
                  of tech-savvy customers, resulting in a more gratifying and
                  modernized pest control experience.
                </div>
                <div className="border"></div>
                <div id="Discovery" className="projectTitle">
                  Discovery
                </div>
                <div className="projectDescription">
                  My research began with a thorough internal analysis of our
                  current website. This included meeting's with marketing to
                  ensure I understood business and growth goals as well as
                  product meeting's with engineers to ensure I understood
                  bandwidth and functionality possibilities. After, I made sure
                  to conduct an extensive competitive analysis, pinpointing
                  industry shortcomings and successes among competitors.
                </div>
                <Zoom>

                <img className="projectShowcaseImg" src={Strategy} alt=""></img>
                </Zoom>
                <div className="imageCaption">
                  I conducted a competitive audit to help identify strengths,
                  weaknesses, and opportunities for improvement in rival
                  products or services.
                </div>
                <div className="projectDescription">
                  {" "}
                  The results of my research indicated 4 shortcomings in our
                  current website design.
                </div>
                <div className="projectShowcaseA">
                  <div className="projectShowcaseCard">
                    <img
                      className="projectShowcaseCardImg"
                      src={Icon1}
                      alt=""
                    ></img>
                    <div className="projectShowcaseCardTitle">
                      Stock Imagery
                    </div>
                    <div className="projectShowcaseCardDesc">
                      Our imagery needs to updated to reflect our new branding
                      and the stock photos need to be removed as it cheapens our
                      brand. Additionally, it is imperative that we highlight
                      what sets us apart, our technicians using our software to
                      create transparency for our customers.
                    </div>
                  </div>
                  <div className="projectShowcaseCard">
                    <img
                      className="projectShowcaseCardImg"
                      src={Icon2}
                      alt=""
                    ></img>
                    <div className="projectShowcaseCardTitle">Career Page</div>
                    <div className="projectShowcaseCardDesc">
                      We need to communicate to future technicians and employees
                      what sets Hawx apart and why they would want to work with
                      us. If we are placing a big emphasis on being a tech
                      company our look and feel needs to show that we are modern
                      and cutting edge to perspective employees.
                    </div>
                  </div>
                  <div className="projectShowcaseCard">
                    <img
                      className="projectShowcaseCardImg"
                      src={Icon3}
                      alt=""
                    ></img>
                    <div className="projectShowcaseCardTitle">
                      Stagnent Design
                    </div>
                    <div className="projectShowcaseCardDesc">
                      The lack of movement on our site makes it a bit dated.
                      Adding more scroll interaction and movement is imperative.
                      This could also include videos, motion graphics etc.
                    </div>
                  </div>
                  <div className="projectShowcaseCard">
                    <img
                      className="projectShowcaseCardImg"
                      src={Icon4}
                      alt=""
                    ></img>
                    <div className="projectShowcaseCardTitle">Self Service</div>
                    <div className="projectShowcaseCardDesc">
                      We lack a self service feature on our site. Adding one
                      would increase convenience for our customer base.
                    </div>
                  </div>
                </div>
                <div id="Execution" className="projectTitle">
                  Execution
                </div>
                <div className="projectDescription">
                  UX designers create both low and high fidelity mockups to
                  refine and communicate design concepts effectively.
                  Low-fidelity mockups focus on quick ideation and concept
                  testing, allowing for rapid iteration and feedback.
                  High-fidelity mockups, on the other hand, provide a detailed
                  and realistic representation of the final product, aiding in
                  precise visualization and usability testing. Together, these
                  approaches ensure a user-centric design process, aligning
                  stakeholder expectations and delivering a seamless,
                  user-friendly end product.
                </div>
                <Zoom>

                <img
                  className="projectShowcaseImg"
                  src={Execution}
                  alt=""
                  ></img>
                  
                  </Zoom>
                <div className="imageCaption">
                  Mockups are an important part of the design process,
                  facilitating rapid iteration and validating user flows.
                </div>
                <div className="projectDescription">
                  {" "}
                  The execution stage is also where it is important to build out
                  your design system and component library. This will serve as a
                  unified blueprint, ensuring consistent visual language,
                  interaction patterns, and brand coherence. It streamlines
                  development, fosters collaboration, and enriches user
                  experiences seamlessly.{" "}
                </div>
                <Zoom>

                <img
                  className="projectShowcaseImg"
                  src={Execution2}
                  alt=""
                  ></img>
                  </Zoom>
                <div className="imageCaption">
                  These components can be used to efficiently create new designs
                  and ensure consistency in future updates.
                </div>

                <div id="Conclusion" className="projectTitle">
                  Conclusion
                </div>
                <div className="projectDescription">
                  After examining user feedback and the mock-ups, I updated the
                  designs and created an interactive prototype of the Hawx Smart
                  Pest Control Website in Figma.
                </div>
                <iframe
                  className="iframe"
                  title="High Fidelty Prototype"
                  id="project1FinalFrame"
                  width="100%"
                  height="840px"
                  src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FCgqYYHtxdErPMldHcpHNk1%2FHawx-Website-Demo%3Ftype%3Ddesign%26node-id%3D1-646%26t%3DmcVR7CyDqE39zwrW-1%26scaling%3Dscale-down%26page-id%3D0%253A1%26starting-point-node-id%3D1%253A646%26mode%3Ddesign"
                  allowfullscreen
                ></iframe>
                <div className="imageCaption">
                  Test out the high fidelity Figma prototype above.
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

export default Project4;
