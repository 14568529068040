import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./Pages/NewPages/Home/Home";
import "./App.css";
import Project1 from "./Pages/NewPages/Project1/Project1";
import Project2 from "./Pages/NewPages/Project2/Project2";
import Project4 from "./Pages/NewPages/Project4/Project4";
import About from "./Pages/About/About";
import Project3 from "./Pages/NewPages/Project3/Project3";
import Project5 from "./Pages/NewPages/Project5/Project5";
import Project6 from "./Pages/NewPages/Project6/Project6";

function App() {
  return (
    <div className="App">
    <BrowserRouter >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/project/Blackbird" element={<Project1 />} />
          <Route path="/project/Digital-Technician" element={<Project2 />} />
          <Route path="/project/Pushpay" element={<Project3/>} />
          <Route path="/project/Hawx-Website" element={<Project4/>} /> 
          <Route path="/project/Nest" element={<Project5 />} />
          <Route path="/project/Wick" element={<Project6 />} />
          <Route path="/about" element={<About/>} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
