import React, { useEffect } from "react";
import Header from "../../../Components/Header/Header";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
// importing image assets
import Icon1 from "./Assets/Icon1.png";
import Icon2 from "./Assets/Icon2.png";
import Icon3 from "./Assets/Icon3.png";
import Icon4 from "./Assets/Icon4.png";
import Strategy from "./Assets/Strategy.png";
import Execution from "./Assets/Execution.png";
import Execution2 from "./Assets/Execution3.png";
import Project1Main from "./Assets/Project1Main.png";

import Aos from "aos";
import "aos/dist/aos.css";

// import "./style.css";

function Project2() {
  useEffect(() => {
    Aos.init({
      duration: 600,
      easing: "ease-in-out",
    });
  }, []);
  return (
    <div>
        <div className="project1">
          <Header></Header>
          <div className="project1__container">
            <div className="projectInfoContainer">
              <div className="projectInfoText">
                <div className="projectInfoTitle">Digital Technician</div>
                <div className="projectInfoDesc">
                Empowering Hawx Pest Control technicians by leveraging world-class technology.
                </div>
              </div>
              <div className="projectInfoImgContainer">
              <img className="projectInfoImg" src={Project1Main} alt="" />
              </div>
            </div>
            <div className="projectContent">
              <div className="leftCol">
                <div className="projectMenu">
                  <div className="border"></div>
                  <a href="#Discovery" className="projectMenuItem">
                    Discovery
                  </a>
                  <a href="#Strategy" className="projectMenuItem">
                    Strategy
                  </a>
                  <a href="#Execution" className="projectMenuItem">
                    Execution
                  </a>
                  <a href="#Conclusion" className="projectMenuItem">
                    Conclusion
                  </a>
                  <div className="border"></div>
                </div>
                
              </div>
              <div className="rightCol">
                <div id="projectDescAdd" className="projectDescription">
                  During my endeavor at Hawx, I worked alongside the engineering
                  team to build an app that enables our technicians to provide
                  premium pest control services to our customers all over the
                  country. This includes real-time job assignments, optimized
                  routes, digital checklist for pest treatments, integration
                  with other Hawx software, as well as an after service digital
                  report to ensure a personalized and seamless customer
                  experience.
                </div>
                <div className="border"></div>
                <div id="Discovery" className="projectTitle">
                  Discovery
                </div>
                <div className="projectDescription">
                  My research consisted of an internal analysis of the issues
                  and shortcomings of our current service process as well as
                  calls with service professionals to better understand user
                  needs. Needing to ramp quickly, we dove head first into an
                  abundance of product meetings with key service leaders as well
                  as c-suite stakeholders who provided a better vision for what
                  was needed by this new app. This research revealed 4 key
                  insights:
                </div>
                <div className="projectShowcaseA">
                  <div className="projectShowcaseCard">
                    <img
                      className="projectShowcaseCardImg"
                      src={Icon1}
                      alt=""
                    ></img>
                    <div className="projectShowcaseCardTitle">
                      {" "}
                      Customer Transparency
                    </div>
                    <div className="projectShowcaseCardDesc">
                      When customers are unable to witness the completion of a
                      service they are left uncertain about the actual execution
                      of tasks. Our solution was to produce a digital service
                      ticket to be sent to customers after service to increase
                      trust.
                    </div>
                  </div>
                  <div className="projectShowcaseCard">
                    <img
                      className="projectShowcaseCardImg"
                      src={Icon2}
                      alt=""
                    ></img>
                    <div className="projectShowcaseCardTitle">
                      Inefficient Scheduling
                    </div>
                    <div className="projectShowcaseCardDesc">
                      Inefficient scheduling practices result in significant
                      financial and time loss for the company. Our solution was
                      to design an optimized scheduler that showcase the best
                      routes for our technicians.
                    </div>
                  </div>
                  <div className="projectShowcaseCard">
                    <img
                      className="projectShowcaseCardImg"
                      src={Icon3}
                      alt=""
                    ></img>
                    <div className="projectShowcaseCardTitle">
                      Communication Flow
                    </div>
                    <div className="projectShowcaseCardDesc">
                      The lines of communication between sales and technicians
                      were poor. Our solution is to create a feature in app to
                      call the office as well as view sales notes on a customer.
                    </div>
                  </div>
                  <div className="projectShowcaseCard">
                    <img
                      className="projectShowcaseCardImg"
                      src={Icon4}
                      alt=""
                    ></img>
                    <div className="projectShowcaseCardTitle">
                      Establishing Uniformity
                    </div>
                    <div className="projectShowcaseCardDesc">
                      Inconsistent customer hand off resulted in sales leaders
                      lacking confidence in the company. Our solution was to
                      create a customer list that informs sales people of
                      customer status to increase retention.
                    </div>
                  </div>
                </div>
                <div className="project2Quote projectQuoteBreak">
                  “Digital Technician was created to enhance operational
                  efficiency, improve customer service, and streamline Hawxs’
                  business processes.”
                </div>
                <div id="Strategy" className="projectTitle">
                  Strategy
                </div>
                <div className="projectDescription">
                  Taking an empathetic approach, designers can anticipate
                  obstacles, customize solutions, and craft captivating
                  experiences that forge a personal connection. For Digital
                  Technician, this entailed crafting user personas, journey
                  maps, and user flows.
                  <br /> <br />
                  Journey maps are valuable tools in the design process for
                  understanding and improving the user experience of a product
                  by visualizing the entire user journey from start to finish,
                  highlighting both emotional and procedural moments that users
                  go through.
                </div>
                <Zoom>
                <img className="projectShowcaseImg" src={Strategy} alt=""></img>
                </Zoom>
                
                <div className="imageCaption">
                  Journey maps and user flows were used to communicate design
                  discoveries to stakeholders.
                </div>
                <div id="Execution" className="projectTitle">
                  Execution
                </div>
                <div className="projectDescription">
                  In UX design, low fidelity mockups facilitate quick idea
                  generation and exploration with their basic representations,
                  fostering creativity and easy iteration. They are invaluable
                  for brainstorming and initial concept validation. Conversely,
                  high fidelity mockups, with their detailed design and
                  realistic interactions, provide a tangible preview of the
                  final product. They help stakeholders and teams visualize the
                  user experience accurately, leading to informed decisions and
                  effective communication. Together, these mockups cover the
                  spectrum of design phases, aiding in both conceptual
                  development and precise execution, ultimately resulting in
                  user-centric and visually polished digital solutions.
                </div>
                <Zoom>

                <img
                  className="projectShowcaseImg"
                  src={Execution}
                  alt=""
                  ></img>
                  </Zoom>
                <div className="imageCaption">
                  Mockups are an important part of the design process,
                  facilitating rapid iteration and validating user flows.
                </div>
                <div className="projectDescription">
                  Sending an after-service ticket to Hawx customers ensures
                  satisfaction and resolution. It serves as a record of the
                  completed work, increasing transparency among our customer
                  base. In addition, this report clarifies any future billing
                  and upcoming service information. This proactive communication
                  fosters trust, enhances customer experience, and maintains a
                  pest-free environment.
                </div>
                <Zoom>

                <img
                  className="projectShowcaseImg"
                  src={Execution2}
                  alt=""
                  ></img>
                  </Zoom>
                <div className="imageCaption">
                  This service ticket was sent to every customer after their service occured.
                </div>
                <div id="Conclusion" className="projectTitle">
                  Conclusion
                </div>
                <div className="projectDescription">
                  After examining user feedback and mock-ups, I updated the
                  designs and created high fidelity prototypes in Figma. This is
                  an ongoing process and is a project that continues to be
                  altered to this day based on feedback from our users. One
                  exciting upcoming project is integrating a manager view to
                  oversee technician routes. 
                  <br /> <br />
                  Digital technician and the
                  companion service ticket resulted in a 10-point increase in
                  our Net Promoter Score along with a daily user base of 4000.
                </div>
                <iframe
                  className="iframe"
                  title="High Fidelty Prototype"
                  id="project1FinalFrame"
                  width="100%"
                  height="840px"
                  src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FpfM1vAeDYhTYgcGps6dws8%2FDIgital-Technician%3Ftype%3Ddesign%26node-id%3D3-6552%26t%3D0yAXFTKj2prtGVth-1%26scaling%3Dcontain%26page-id%3D0%253A1%26starting-point-node-id%3D3%253A6552%26mode%3Ddesign"
                  allowfullscreen
                ></iframe>
                <div className="imageCaption">
                  Test out the high fidelity Figma prototype above.
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

export default Project2;
