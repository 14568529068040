import React, { useEffect } from "react";
import Header from "../../../Components/Header/Header";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
// importing image assets
import Icon1 from "./Assets/Icon1.png";
import Icon2 from "./Assets/Icon2.png";
import Icon3 from "./Assets/Icon3.png";
import Icon4 from "./Assets/Icon4.png";
import Strategy from "./Assets/Strategy.png";
import Execution from "./Assets/Execution.png";
import Execution2 from "./Assets/Execution2.png";
import Project1Main from "./Assets/Project1Main.png";

import Aos from "aos";
import "aos/dist/aos.css";

import "./style.css";

function Project1() {
  useEffect(() => {
    Aos.init({
      duration: 600,
      easing: "ease-in-out",
    });
  }, []);

  return (
    <div>
        <div className="project1">
          <Header></Header>
          <div className="project1__container">
            <div className="projectInfoContainer">
              <div className="projectInfoText">
                <div className="projectInfoTitle">BlackBird</div>
                <div className="projectInfoDesc">
                  Revolutionizing the way our sales team attracts, converts, and
                  retains customers.
                </div>
              </div>
              <div className="projectInfoImgContainer">
              <img className="projectInfoImg" src={Project1Main} alt="" />
              </div>
            </div>
            <div className="projectContent">
              <div className="leftCol">
                <div className="projectMenu">
                  <div className="border"></div>
                  <a href="#Discovery" className="projectMenuItem">
                    Discovery
                  </a>
                  <a href="#Strategy" className="projectMenuItem">
                    Strategy
                  </a>
                  <a href="#Execution" className="projectMenuItem">
                    Execution
                  </a>
                  <a href="#Conclusion" className="projectMenuItem">
                    Conclusion
                  </a>
                  <div className="border"></div>
                </div>
                
              </div>
              <div className="rightCol">
                <div id="projectDescAdd" className="projectDescription">
                  For many businesses the utilization of third party
                  applications can create a plethora of issues. These range from
                  customization limitations, integration challenges, dependency
                  on external providers, and excessive costs. For Hawx pest
                  control, this led to the creation of the Blackbird sales app
                  to aid in the sale of pest control subscriptions.
                </div>
                <div className="border"></div>
                <div id="Discovery" className="projectTitle">
                  Discovery
                </div>
                <div className="projectDescription">
                  {" "}
                  My research consisted of an internal analysis of the issues
                  and shortcomings of the current software as well as a
                  competitive analysis of other software in the industry.
                  Product meetings with key sales leaders as well as c-suite
                  stakeholders provided a better vision for what was needed by
                  this new app. While this app was going to be mandatory to use,
                  we still wanted to make the experience as user friendly as
                  possible. This research revealed 4 key insights on issues we
                  were facing:
                </div>
                <div className="projectShowcaseA">
                  <div className="projectShowcaseCard">
                    <img
                      className="projectShowcaseCardImg"
                      src={Icon1}
                      alt=""
                    ></img>
                    <div className="projectShowcaseCardTitle">
                      {" "}
                      Product Integration
                    </div>
                    <div className="projectShowcaseCardDesc">
                      The lines of communication between sales and technicians
                      were poor. Our solution was to add a special request for
                      service pros in the sales app to increase clear
                      communication.
                    </div>
                  </div>
                  <div className="projectShowcaseCard">
                    <img
                      className="projectShowcaseCardImg"
                      src={Icon2}
                      alt=""
                    ></img>
                    <div className="projectShowcaseCardTitle">
                      {" "}
                      Customer Transparency
                    </div>
                    <div className="projectShowcaseCardDesc">
                      There are issues with transparency within the sales
                      process leading to poor retention. Our solution was to
                      create a welcome letter to simplify contract terms.
                    </div>
                  </div>
                  <div className="projectShowcaseCard">
                    <img
                      className="projectShowcaseCardImg"
                      src={Icon3}
                      alt=""
                    ></img>
                    <div className="projectShowcaseCardTitle">
                      {" "}
                      Sales Handoff
                    </div>
                    <div className="projectShowcaseCardDesc">
                      Inconsistent customer hand off resulted in sales leaders
                      lacking confidence in the company. Our solution was to
                      create a customer list that informs sales people of
                      customer status to increase retention.
                    </div>
                  </div>
                  <div className="projectShowcaseCard">
                    <img
                      className="projectShowcaseCardImg"
                      src={Icon4}
                      alt=""
                    ></img>
                    <div className="projectShowcaseCardTitle">
                      {" "}
                      Inefficient Scheduling
                    </div>
                    <div className="projectShowcaseCardDesc">
                      Non-optimized, initial appointment scheduling in the sales
                      app is costing the companies millions in profits. Our
                      solution was to create an optimized scheduler that
                      showcased the best routes for technicians.
                    </div>
                  </div>
                </div>
                <div className="projectQuoteBreak">
                  “Blackbird is based on a foundational idea that creating
                  integrated systems within a company, results in a seamless
                  customer experience - from sale to service.”
                </div>
                <div id="Strategy" className="projectTitle">
                  Strategy
                </div>
                <div className="projectDescription">
                  Fostering empathy in user experience design is paramount as it
                  cultivates a deep understanding of users' emotions, needs, and
                  perspectives. By empathizing with users, designers can
                  anticipate challenges, tailor solutions, and craft engaging
                  experiences that resonate on a personal level. For Blackbird,
                  this meant creating user personas, journey maps, and user
                  flows.
                  <br /> <br />
                  Through the creation and exploration of journey maps we are
                  able to discover crucial emotional and procedural moments that
                  required attention from our design team. One example of this
                  is a need for reliability of the sales app. With the
                  inconsistent nature of income for a salesperson, it is
                  important that they are able to rely on the technology that
                  they use. Another glaring problem is loss of customers
                  overtime when the hand off of a sale to service goes poorly.
                  Gaining the trust of the customer is imperative to retaining
                  their sale and this can’t happen without easy access to their
                  salesperson.
                </div>
                <Zoom><img className="projectShowcaseImg" src={Strategy} alt=""></img></Zoom>
                <div className="imageCaption">
                  Journey maps and user flows were used to communicate design
                  discoveries to stakeholders.
                </div>
                <div id="Execution" className="projectTitle">
                  Execution
                </div>
                <div className="projectDescription">
                  Creating wireframes for "Blackbird" was essential for
                  effective design planning and development. They foster clear
                  communication among teams and stakeholders, aiding discussions
                  and decisions. Wireframes streamline development by serving as
                  a reference for accurate implementation and cost-effective
                  testing, ensuring a well-structured and successful app.
                  <br /> <br />
                  Employing both low and high fidelity mockups for "Blackbird"
                  serves as a dual-strategy approach that enriches the design
                  process. Low-fidelity mockups provide a swift means of
                  exploring diverse design concepts, facilitating rapid
                  iteration and validating user flows. They uncover potential
                  usability issues and refine user experience early on.
                  Meanwhile, high-fidelity mockups offer a detailed
                  representation of the app's interface, spotlighting refined
                  design elements, branding, and interactive features. These
                  mockups enhance communication with stakeholders and
                  developers, ensuring a seamless transition from design to
                  implementation.
                </div>
                <Zoom>
                <img
                  className="projectShowcaseImg"
                  src={Execution}
                  alt=""
                  ></img>
                  </Zoom>
                <div className="imageCaption">
                  Mockups are an important part of the design process,
                  facilitating rapid iteration and validating user flows.
                </div>
                <div className="projectDescription">
                  The execution stage is also where it is important to build out
                  your design system and component library. This will serve as a
                  unified blueprint, ensuring consistent visual language,
                  interaction patterns, and brand coherence. It streamlines
                  development, fosters collaboration, and enriches user
                  experiences seamlessly.
                </div>
                <Zoom>

                <img
                  className="projectShowcaseImg"
                  src={Execution2}
                  alt=""
                  ></img>
                </Zoom>
                <div className="imageCaption">
                  The design system made for Blackbird. These components can be
                  used to efficiently create new designs.
                </div>
                <div id="Conclusion" className="projectTitle">
                  Conclusion
                </div>
                <div className="projectDescription">
                  After examining user feedback and the mock-ups, I updated the
                  designs and created high fidelity prototypes in Figma. This is
                  an ongoing process and is a project that continues to be
                  altered to this day based on feedback from our users.
                  <br /> <br />
                  Projects like Blackbird contributed to a 375% increase in
                  company profits. Blackbird continues to evolve and we continue
                  to see areas for growth and improvement. One exciting upcoming
                  project is integrating area management to assign sales areas.
                </div>
                <iframe
                  className="iframe"
                  title="High Fidelty Prototype"
                  id="project1FinalFrame"
                  width="100%"
                  height="840px"
                  src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fx2gOC99VO8hihGsF3pj3fm%2FSales-App%3Ftype%3Ddesign%26node-id%3D4-6549%26t%3DsmMMfe8opuxew4B4-1%26scaling%3Dcontain%26page-id%3D0%253A1%26starting-point-node-id%3D4%253A6549%26mode%3Ddesign"
                  allowfullscreen
                ></iframe>
                <div className="imageCaption">
                  Test out the high fidelity Figma prototype above.
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

export default Project1;
