import React from "react";
import "./style.css";
import sydney from "./assets/sydney.png";
import ContactContainer from "../../Components/ContactContainer/ContactContainer";
import Footer from "../../Components/Footer/Footer";

function About() {
  return (
    <div id="about">
      <div id="aboutTitleContainer">
        <div id="aboutIntro">Hi, my name is</div>
        <div id="aboutTitle">Sydney Martin</div>
      </div>
      <div id="aboutContent">
        <div id="imagesContainer">
        <div id="aboutImgContainer">
          <img id="aboutImg" src={sydney} alt="Sydney"/>
        </div>
        </div>
        <div className="descTextMain" id="aboutText">
          I am a seasoned UX/UI design professional with over three years of
          experience, specializing in creating user-centered digital products
          for web and mobile. As the UX/UI Design Lead at Hawx Smart Pest
          Control, I successfully led a team in redesigning the customer-facing
          website and developing an intuitive Sales App for iPad, resulting in
          enhanced user experience and visual appeal. My expertise includes
          design thinking, user research, prototyping, and team management.
          During my tenure, I contributed to a 10-point increase in the Net Promoter
          Score by launching a customer service ticket design. With a strong
          background in remote collaboration, wireframing, prototyping, and
          accessibility, I successfully built digital product
          strategies in partnership with various stakeholders. My track record
          also includes designing custom apps for international clients and
          excelling in customer-driven environments. I am skilled in Sketch,
          Figma, and motion graphics creation. My dedication to refining designs
          based on user feedback and driving successful project outcomes
          showcases my commitment to delivering impactful and visually appealing
          digital solutions.
        </div>
      </div>
      <ContactContainer />
      <Footer/>
    </div>
  );
}

export default About;
