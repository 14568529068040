import React, {useEffect} from "react";
import "./style.css";
import EmailForm from "./EmailForm";
import Aos from "aos";
import "aos/dist/aos.css";

const ContactContainer = () => {

  
    useEffect(() => {
     
      Aos.init({      
        duration: 600,
        easing: 'ease-in-out',
        });
  }, []);
 
  
  return (
    <div id="Contact">

    <div data-aos="zoom-in" data-aos-once="true" id="contactCon">
        <div id="formContainer">
        <div id="contactTextBox">
            <div id="contactTitle">I'd love to hear from you!</div>
            <div id="contactText">Feel free to fill out the form below or send me an email anytime at sydneykmartin1@gmail.com.</div>
        </div>
            <EmailForm/>
        </div>
      </div>
    </div>
  );
};

export default ContactContainer;
