import React from "react";
import "./style.css";
import Project1 from "./Assets/Project1.png";
import Project2 from "./Assets/Project2.png";
import Project3 from "./Assets/Project3.png";
import Project4 from "./Assets/Project4.png";
import Project5 from "./Assets/Project5.png";
import Project6 from "./Assets/Project6.png";
import Footer from "../../../Components/Footer/Footer";

function Home() {

  return (
    <div id="homeContainer">
      <div id="allHome">
        <div id="topInfo">
          <div className="jobTitle">Product and UX Designer</div>
          <div id="homeTitle">Hi, I'm Sydney!</div>
          <div className="descText homeDescText">
            I’m a designer based out of Seattle, WA who is passionate about
            creating intuitive and engaging digital experiences that fulfill
            user needs.{" "}
          </div>
        </div>
        
        <div id="cardContainer">
        <div className="homeCard">
            <a className="hoverCursor" href="/project/Wick">
            <img className="cardImg" src={Project6} alt="Project 1" />
            </a>
            <div className="cardInfo">
              <div className="jobTitle">Product Designer</div>
              <div className="cardTitleContainer">
              <a  href="/project/Wick" className="titleATag hoverCursor">
                <div className="cardTitleText">Wick</div>
                
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7 17L17 7M17 7H7M17 7V17"
                    stroke="#101828"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                </a>
              </div>
              <div className="cardSubtext">
                {" "}
                Building the ultimate online community for investors to learn, engage, and connect.
              </div>
            </div>
          </div>
          <div className="homeCard">
            <a  className="hoverCursor" href="/project/Nest">
            <img className="cardImg" src={Project1} alt="Project 1" />
            </a>
            <div className="cardInfo">
              <div className="jobTitle">UX/UI Contractor</div>
              <div className="cardTitleContainer">
              <a  href="/project/Nest" className="titleATag hoverCursor">
                <div className="cardTitleText">The Nest</div>
                
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7 17L17 7M17 7H7M17 7V17"
                    stroke="#101828"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                </a>
              </div>
              <div className="cardSubtext">
                {" "}
                Effortless onboarding, seamless management for thriving D2D sales teams.
              </div>
            </div>
          </div>
          <div className="homeCard">
            <a href="/project/Blackbird">
            <img className="cardImg" src={Project2} alt="Project 2" />
              </a>
            <div className="cardInfo">
              <div className="jobTitle">UX/UI Design Lead</div>
              <div className="cardTitleContainer">
              <a className="titleATag" href="/project/Blackbird">
                <div className="cardTitleText">Blackbird</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7 17L17 7M17 7H7M17 7V17"
                    stroke="#101828"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                </a>
              </div>
              <div className="cardSubtext">
              Revolutionizing the way our sales team attracts, converts, and retains customers.
              </div>
            </div>
          </div>

          <div className="homeCard">
            <a href="/project/Hawx-Website">
            <img className="cardImg" src={Project4} alt="Project 4" />
              </a>
            <div className="cardInfo">
              <div className="jobTitle">UX/UI Designer</div>
              <div className="cardTitleContainer">
              <a className="titleATag" href="/project/Hawx-Website">
                <div className="cardTitleText">Hawx Website</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7 17L17 7M17 7H7M17 7V17"
                    stroke="#101828"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                </a>
              </div>
              <div className="cardSubtext">
              Changing the pest control industry one exceptional service at a time.
              </div>
            </div>
          </div>
          <div className="homeCard">
            <a href="/project/Digital-Technician">
            <img className="cardImg" src={Project3} alt="Project 3" />
              </a>
            <div className="cardInfo">
              <div className="jobTitle">UX/UI Designer</div>
              <div className="cardTitleContainer">
                <a className="titleATag" href="/project/Digital-Technician">
                <div className="cardTitleText">Digital Technician</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7 17L17 7M17 7H7M17 7V17"
                    stroke="#101828"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                </a>
              </div>
              <div className="cardSubtext">
              Empowering technicians with World-Class Technology.
              </div>
            </div>
          </div>
          <div className="homeCard">
            <a href="/project/Pushpay">
            <img className="cardImg" src={Project5} alt="Project 5" />
              </a>
            <div className="cardInfo">
              <div className="jobTitle">App Designer</div>
              <div className="cardTitleContainer">
              <a className="titleATag" href="/project/Pushpay">
                <div className="cardTitleText">Pushpay</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7 17L17 7M17 7H7M17 7V17"
                    stroke="#101828"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                </a>
              </div>
              <div className="cardSubtext">
              Helping our users engage and build their communities with their own custom app. 
              </div>
            </div>
          </div>
          
          
          <div className="homeCard"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
